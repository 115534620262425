<template>
  <PrepTasks
    team="chopping"
    :team-filter="teamFilter"
    show-late-warnings
    :late-warning-priority-deadlines="lateWarningPriorityDeadlines"
    :can-set-estimate="!$route.meta.readOnly"
  />
</template>

<script>

import PrepTasks from '@/components/tasks/PrepTasks';

export default {
  name: "ChoppingTasks",
  components: {PrepTasks},
  data() {
    return {
      teamFilter: ['prep', 'veg', 'misc'],
      lateWarningPriorityDeadlines: {
        1: 13,
        2: 14,
        3: 15
      }
    }
  }
}
</script>

<style scoped>

</style>
